<template>
  <section class="welcome">
    <section class="welcome__hero">
      <div class="welcome__hero__left d-flex flex-column">
        <Logo class="large mb-5"/>
        <h2 class="mb-4">Writing for a talent or recruitment audience?</h2>
        <h3>It’s not just about understanding them. It’s about making sure they understand you.</h3>
        <div class="welcome__hero__award d-flex mt-auto">
          <img src="@/assets/recruitment-marketing-awards-2022.png" alt="Recruitment marketing awards 2022 logo" width="152" height="96" loading="lazy">
          <p class="ml-4">Our tool won the <a href="https://thermas.co.uk/winners-2022/" target="_blank" rel="noopener">Technology Innovation award</a> at the 2022 RMAs</p>
        </div>
      </div>
      <img src="@/assets/hero-image.jpg" alt="" width="630" height="745" loading="lazy" fetchpriority="high">
    </section>
    
    <section class="section welcome__intro">
      <div class="right-container">
        <h2 class="mb-4">Did you know that the average UK reading age is just 9 years old?</h2>
        <h3 class="mb-4">Or that lots of people struggle with long sentences? Or that masculine biased copy often gets a poor response from women?</h3>
        <p>Whoever your audience is, we can build a detailed report around whether your careers site web page or job listing talks to them on their level. And if it doesn’t, we can help you improve it.</p>
      </div>
    </section>

    <section class="section welcome__how-it-works">
      <div class="right-container">
        <h2 class="h3">These days, content moves just as fast as the audiences we speak to - especially in the recruitment and talent market. That’s why it’s never been more important to deliver messages that are not just on brand, but on target, too. It all begins with making sure your content is aimed at exactly the right level - and our readability tool can help you do just that.</h2>

        <h2 class="mt-5 mb-3">How it works</h2>
        <p class="mb-3">Using the tool is simple. All you need to do is tell us the URL of your content - this could be an online job ad, or a page from your careers website. We’ll scan your page and send you a detailed report around how it measures up when it comes to readability, gender bias, tone and more. The tool is free - use it as often as you like.</p>
        <p class="mb-3">We do also have the ability to bulk-scan many website pages at a time, or automatically rate new jobs as they appear on your careers website - <a href="#get-in-touch">drop us a line to find out more</a>.</p>

        <h2 class="mt-5 mb-3">Why readability matters</h2>
        <p>If you’re responsible for creating recruitment, talent or candidate focused content, it’s important to make sure it talks to the right audience. And that it works as hard as possible to persuade that audience to act, too. That means thinking about reading age, gender bias, sentence length, tone and lots more. All of which are huge (and often hidden) factors in helping someone decide whether to click that ‘Apply now’ button, or not.</p>
      </div>

      <img src="@/assets/tool-screenshot.png" alt="A screenshot of the Readability tool in action" width="680" height="410" class="tool-img" loading="lazy">
    </section>

    <section class="section welcome__main-articles">
      <div class="right-container">
        <!-- gendered words -->
        <article class="welcome__main-articles__article mb-5">
          <div class="row">
            <div class="col-md-2">
              <img src="@/assets/icons/gendered-words-icon.svg" width="66" height="88" alt="Genered Words Icon" loading="lazy">
            </div>
            <div class="col-md-10">
              <h2 class="mb-3">Gender Bias</h2>
              <p class="mb-3">Lots of words have hidden implications when it comes to gender. Some words have a natural masculine bias, while others have a natural feminine bias. The frequency that these words are used in text can often have a subconscious influence over our audiences. And this can impact the way our audiences respond.</p>
              <a href="http://gender-decoder.katmatfield.com/static/documents/Gaucher-Friesen-Kay-JPSP-Gendered-Wording-in-Job-ads.pdf" target="_blank" rel="noopener">Evidence that gendered wording in job advertistments exists and sustains gender inequality by Danielle Gaucher, Justin Friesen, and Aaron C. Kay, 2011</a>
            </div>
          </div>
        </article>
        <!-- readability -->
        <article class="welcome__main-articles__article mb-5">
          <div class="row">
            <div class="col-md-2">
              <img src="@/assets/icons/reading-age-icon.svg" width="115" height="53" alt="Reading Age Icon" loading="lazy">
            </div>
            <div class="col-md-10">
              <h2 class="mb-3">Readability</h2>
              <p class="mb-3">Did you know that the average reading age in the UK is 9 years old? Or that 10% of the UK population has Dyslexia, and around 7% of the population don't have English as their first language? That means it's important to write in a way that our audiences are able to easily understand.</p>
              <a href="https://en.wikipedia.org/wiki/Flesch%E2%80%93Kincaid_readability_tests" target="_blank" rel="noopener">We use the universally recognised Flesch-Kincaid score</a>
            </div>
          </div>
        </article>
        <!-- passive voice -->
        <article class="welcome__main-articles__article">
          <div class="row">
            <div class="col-md-2">
              <img src="@/assets/icons/voice-icon.svg" width="84" height="72" alt="Passive Voice Icon" loading="lazy">
            </div>
            <div class="col-md-10">
              <h2 class="mb-3">Passive Voice</h2>
              <p class="mb-3">A quick way to improve the tone of your content is to make sure you write with an active voice rather than a passive one. You’ll find lots of explanations of the grammatical rules behind this idea on the web, but a quick example is a great way to tell the story.</p>
              <a href="https://grammar.yourdictionary.com/style-and-usage/active-voice-adds-impact-to-your-writing.html" target="_blank" rel="noopener">See explanation</a>
            </div>
          </div>
        </article>
      </div>
    </section>

    <section class="section welcome__about">
      <div class="right-container">
        <img src="@/assets/chatter-group-picture.jpg" alt="A group picture with a few of the Chatter team" width="680" height="370" class="intro-img" loading="lazy">
        <h2 class="mb-3">About Chatter</h2>
        <p>We’re Chatter - an award-winning full service Employer Branding agency that thinks big, and gets things delivered with no nonsense and maximum wow! That means we can help you engage employees and candidates with strategic consultancy, creative print and digital campaigns, full careers websites, and… of course… <em>incredibly</em> readable copy.</p>
        <p>Based in Leeds, we’ve been around since 2011 and work with brands of all shapes and sizes across the UK, Europe and further afield.</p>
        <p>So if you still need a hand with any of that after you’ve tried our readability tool, just get in touch via the contact form below.</p>
        <ContactForm class="mt-5"/>
      </div>
    </section>
  </section>
</template>

<script>
import Logo from '@/components/Logo.vue';
import ContactForm from '@/components/ContactForm.vue'

export default {
  components: {
    Logo, ContactForm,
  },
};
</script>

<style lang="scss" scoped>
.welcome {
  &__hero {
    position: relative;
    display: grid;
    // grid-template-columns: 340px 630px;
    grid-template-columns: 340px 1fr;
    color: #fff;
    background-color: $primary-colour;

    > * { min-width: 0; }

    a {
      color: inherit;
      text-decoration: underline;
    }

    &__left {
      position: relative;
      padding: 60px 10px 60px 60px;
      z-index: 1;
    }

    &__award {
      position: absolute;
      bottom: 60px;
      left: 60px;
      width: 100vw;
      max-width: 460px;
      font-size: 14px;

      p {
        padding-top: 14px;
      }
    }
  }

  &__intro {
    color: #fff;
    background-color: $primary-colour-dark;
  }

  $tool-img-section-spacing: 140px;

  &__how-it-works {
    padding-bottom: 0;

    :is(h2, .h2) {
      color: $primary-colour;
    }

    :is(h3, .h3) {
      color: inherit;
    }

    .tool-img {
      margin-top: $padding-content-h-md;
      // 70px is the transparent region of the image.
      margin-bottom: calc(($tool-img-section-spacing + 70px) * -1);
    }
  }

  &__main-articles {
    padding-top: calc($padding-content-h-md + $tool-img-section-spacing);
    color: #fff;
    background-color: $primary-colour-dark;

    a {
      color: inherit;
      text-decoration: underline;
    }
  }

  &__about {
    position: relative;
    padding-top: 0;
    background-color: $colour-grey;
    isolation: isolate;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: $padding-content-h-md;
      background-color: $primary-colour-dark;
      z-index: -1;
    }

    :is(h1, h2, .h1, .h2) {
      color: $primary-colour;
    }

    .intro-img {
      margin-bottom: $padding-content-h;
    }
  }
}
</style>