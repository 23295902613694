<template>
  <header class="header">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-2">
          <img src="@/assets/chatter-logo.svg" width="88" height="51" alt="Chatter Logo" />
        </div>
        <div class="col-6">
          <Logo/>
        </div>
        <div class="col-4 text-right">
          <a href="https://welovechatter.com/products/" target="_blank" rel="noopener">See more products</a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import Logo from './Logo.vue';

export default {
  components: {
    Logo,
  },
};
</script>

<style lang="scss" scoped>
.header {
  --main-logo-colour: #{$primary-colour};
  --main-logo-secondary-colour: #fff;
  padding-block: 25px;
  background-color: #fff;

  .container {
    max-width: 1500px;
    padding-inline: 60px;
  }
}
</style>