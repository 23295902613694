<template>
  <form class="get-in-touch" id="get-in-touch" :class="{ 'loading': loading }" ref="contact" @submit="formSubmit">
    <h2 class="get-in-touch__heading mb-3">Get In Touch</h2>
    <div class="get-in-touch__form-container" :class="{ 'success': finished && !error }">
      <p class="get-in-touch__name-and-email">
        <span>People call me</span>
        <label class="sr-only" for="getintouch-sender_name">Full Name</label>
        <input type="text" name="sender_name" id="getintouch-sender_name" required="required" placeholder="full name" ref="fullName" :disabled="loading"/>
        <span>, my email is</span>
        <label class="sr-only" for="getintouch-sender_email">Email Address</label>
        <input type="email" name="sender_email" id="getintouch-sender_email" required="required" placeholder="email address" ref="emailAddress" :disabled="loading"/>
        <span>and I’d love to talk about:</span>
      </p>
      <div class="message-wrapper mb-3">
        <label class="sr-only" for="getintouch-message">Message</label>
        <textarea name="message" id="getintouch-message" placeholder="Write your message here" required="required" :disabled="loading"></textarea>
        <div id="captcha"></div>
      </div>
      <div class="d-flex align-items-center">
        <button type="submit" name="submit" id="getintouch-submit" class="button button--loading-spinner" :disabled="loading || !recaptchaVerified">
          <img src="@/assets/icons/spinner.svg" width="24" height="24"/>
          <span>Submit</span>
        </button>
        <p v-if="finished && error" class="get-in-touch__error ml-3">There was an error sending your message. Try again later!</p>
        <div class="get-in-touch__success">
          <svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="84" height="84" viewBox="0 0 512 512">
            <path d="M489 255.9v-.7c0-1.6 0-3.2-.1-4.7 0-.9-.1-1.8-.1-2.8 0-.9-.1-1.8-.1-2.7-.1-1.1-.1-2.2-.2-3.3 0-.7-.1-1.4-.1-2.1l-.3-3.6c0-.5-.1-1.1-.1-1.6-.1-1.3-.3-2.6-.4-4 0-.3-.1-.7-.1-1C474.3 113.2 375.7 22.9 256 22.9S37.7 113.2 24.5 229.5c0 .3-.1.7-.1 1-.1 1.3-.3 2.6-.4 4-.1.5-.1 1.1-.1 1.6l-.3 3.6c0 .7-.1 1.4-.1 2.1-.1 1.1-.1 2.2-.2 3.3 0 .9-.1 1.8-.1 2.7 0 .9-.1 1.8-.1 2.8 0 1.6-.1 3.2-.1 4.7V256.9c0 1.6 0 3.2.1 4.7 0 .9.1 1.8.1 2.8 0 .9.1 1.8.1 2.7.1 1.1.1 2.2.2 3.3 0 .7.1 1.4.1 2.1l.3 3.6c0 .5.1 1.1.1 1.6.1 1.3.3 2.6.4 4 0 .3.1.7.1 1C37.7 398.8 136.3 489.1 256 489.1s218.3-90.3 231.5-206.5c0-.3.1-.7.1-1 .1-1.3.3-2.6.4-4 .1-.5.1-1.1.1-1.6l.3-3.6c0-.7.1-1.4.1-2.1.1-1.1.1-2.2.2-3.3 0-.9.1-1.8.1-2.7 0-.9.1-1.8.1-2.8 0-1.6.1-3.2.1-4.7v-.7-.2c0 .1 0 .1 0 0z" fill="currentColor"/>
            <g>
              <path class="st1" d="M213.6 344.2l156.1-156"/>
              <path class="st1" d="M233.8 345.2l-79.1-79.1"/>
            </g>
          </svg>
          <p class="mt-3">Thanks! We've got your message and will be in touch shortly!</p>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
/* global grecaptcha */
import autosizeInput from 'autosize-input'

export default {
  data() {
    return {
      nameAutosizeCleanup: null,
      emailAutosizeCleanup: null,
      loading: false,
      finished: false,
      error: false,
      recaptchaVerified: false,
    };
  },
  created() {
    if (typeof window.grecaptcha === 'undefined') {
      const script = document.createElement('script');
      script.src = 'https://www.google.com/recaptcha/api.js?render=explicit';
      script.setAttribute('async', 'async');
      script.setAttribute('defer', 'defer');
      document.body.append(script);

      // render recaptcha on load
      script.onload = () => {
        this.renderRecaptcha();
      };
    } else {
      this.renderRecaptcha();
    }
  },
  mounted() {
    const { fullName, emailAddress } = this.$refs;

    const autosizeInputs = () => {
      this.nameAutosizeCleanup = autosizeInput(fullName);
      this.emailAutosizeCleanup = autosizeInput(emailAddress);
    }

    if ('fonts' in document) {
      document.fonts.ready.then(autosizeInputs);
    } else {
      setTimeout(autosizeInputs, 1000);
    }
  },
  beforeUnmount() {
    this.nameAutosizeCleanup?.();
    this.emailAutosizeCleanup?.();
  },
  methods: {
    renderRecaptcha() {
      grecaptcha.ready(() => {
        grecaptcha.render('captcha', {
          sitekey: '6LfDhyEaAAAAACt3InoE13Fyf80xidsnw92HZN7e',
          callback: () => this.recaptchaVerified = true,
          'expired-callback': () => this.recaptchaVerified = false,
        });
      });
    },
    formSubmit($event) {
      $event.preventDefault();

      if (!this.recaptchaVerified) {
        return;
      }

      this.loading = true;
      this.finished = false;
      this.error = false;

      const formData = new FormData(this.$refs.contact);

      const fetchOpts = {
        method: 'POST',
        cache: 'no-cache',
        body: formData,
      };

      fetch(`/api/contact`, fetchOpts)
        .then(res => res.json())
        .then((data) => {
          console.log(data);
          this.error = (data?.status === 'errors');
        })
        .catch((err) => {
          console.error(err);
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
          this.finished = true;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.get-in-touch {
  &__heading {
    color: $primary-colour;
  }

  &__form-container {
    position: relative;

    &.success {
      user-select: none;
      pointer-events: none;

      .get-in-touch__success {
        opacity: 1;
        visibility: visible;
      }
    }

    .message-wrapper {
      padding: 8px;
      background-color: #fff;
    }
  }

  &__name-and-email {
    font-size: 18px;
    font-weight: 900;
    margin-bottom: 30px;

    input {
      font-size: inherit;
      font-weight: inherit;
      background-color: transparent;
      border: none;
      color: $primary-colour;
      border-bottom: 1px solid $primary-colour;
      max-width: 100%;
      margin: 0 2px;
      transition-property: opacity;

      &::placeholder {
        color: $primary-colour;
      }
    }
  }

  textarea {
    width: 100%;
    max-width: 100%;
    min-height: 202px;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 6px;
    padding: 4px 2px;
    // background-image: repeating-linear-gradient(#fff, #fff 25px, #000 26px);
    border: none;
    resize: vertical;
    transition-property: opacity;
  }

  &__success {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 20px;
    font-size: 24px;
    font-weight: 700;
    color: $colour-blue;
    text-align: center;
    background-color: #fff;
    opacity: 0;
    visibility: hidden;
    transition: opacity 150ms linear, visibility 150ms linear;
  }

  &__error {
    color: $colour-red;
  }
}

.st1 {
  fill: none;
  stroke: #fff;
  stroke-width: 30;
  stroke-miterlimit: 10;
}
</style>