<template>
  <div class="main-logo">
    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 63 63">
      <defs>
        <clipPath id="a">
          <path fill="none" d="M0 0h63v63H0z"/>
        </clipPath>
      </defs>
      <g>
        <path class="background-colour" fill="#6d49c3" d="M0 0h63v63H0z"/>
        <g opacity=".07" style="mix-blend-mode:multiply;isolation:isolate">
          <g>
            <g clip-path="url(#a)">
              <path d="M63 63 0 0h63Z" fill="#232323"/>
            </g>
          </g>
        </g>
        <g class="text-colour" fill="#fff">
          <path d="m33.062 36.889-1.008-1.305c4.437-1.928 5.566-4.694 5.566-7.931 0-4.692-3.493-9.022-10.223-9.022H14.588V44.86h7.421v-8.513h1.9l.39.542ZM22.009 24.56h5.385a2.95 2.95 0 0 1 2.909 3.238 2.748 2.748 0 0 1-2.837 3.056h-5.457Z"/>
          <path d="M40.639 44.788 51.63 18.634h-6.846l-8.661 20.5Z"/>
          <path d="M34.11 37.561h-7.982l5.675 7.3h7.977Z"/>
        </g>
      </g>
    </svg>
    <h2 class="h2 mb-0 ml-2 ml-sm-3"><strong>Readability</strong> Tool</h2>
  </div>
</template>

<style lang="scss" scoped>
.main-logo {
  display: flex;
  align-items: center;
  color: var(--main-logo-colour, #fff);

  &.large {
    svg { width: 62px; height: 62px; }

    h2 {
      font-size: 14px;
      strong { font-size: 20px; }
    }
  }

  .background-colour { fill: var(--main-logo-colour, #fff); }
  .text-colour { fill: var(--main-logo-secondary-colour, $primary-colour); }

  h2 {
    font-size: 10px;
    font-weight: 300;
    line-height: 1;
    text-transform: uppercase;

    strong {
      display: block;
      font-size: 16px;
    }
  }
}
</style>