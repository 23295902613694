<template>
  <Header/>
  <!-- main -->
  <main class="main" :class="{'on-auth-page': authPage}">
    <aside class="sidebar">
      <h2 class="mb-5">Get started now &mdash; It's completely <strong>free</strong>!</h2>
      <p>Simply paste in the web address of a job description, or the text, and your email address, and click 'Scan'. We'll then email you a report on our findings, along with advice on how you could improve things.</p>
      <form class="sidebar__form mt-4" :class="{ 'loading': loading }" @submit="formSubmit">
        <div class="mb-2">
          <label class="sr-only" for="urlOrText">Webpage URL or text to analyse</label>
          <textarea class="sidebar__form__input" :class="inputTextClass" id="urlOrText" placeholder="Paste URL or text here" :disabled="formDisabled" v-model="inputText" required></textarea>
        </div>
        <div class="mb-2">
          <label class="sr-only" for="emailAddress">Email address</label>
          <input type="email" class="sidebar__form__input sidebar__form__input--email mb-2" id="emailAddress" placeholder="Email address" :disabled="formDisabled" v-model="emailAddress" required/>
          <p class="sidebar__form__small">Your email address will be used to email you the automatically generated report within a couple of minutes.</p>
        </div>
        <!-- subscribe to offers checkbox -->
        <div class="sidebar__form__checkbox mt-3 mb-3">
          <input type="checkbox" id="subscribeToOffers" :disabled="formDisabled" v-model="subscribeToOffers"/>
          <label for="subscribeToOffers">I'd like to occasionally receive tips and tricks about improving my careers communications and information on new products and services that Chatter offer.</label>
        </div>
        <button type="submit" :disabled="formDisabled" class="button button--loading-spinner">
          <img src="@/assets/icons/spinner.svg" width="24" height="24"/>
          <span>Scan</span>
        </button>
      </form>
    </aside>
    <div class="content">
      <router-view v-if="!finished" :key="$route.fullPath"></router-view>
      <!-- ajax result -->
      <section v-if="finished && !error" class="ajax-result success">
        <div class="right-container">
          <img src="@/assets/success.svg" alt="" aria-hidden="true" />
          <h2 class="mt-3 mb-3">Success!</h2>
          <p>We've got your request and are processing it. Once your report is ready, we'll send a link to your email address with instructions on how to view it.</p>
        </div>
      </section>
      <section v-else-if="finished && error" class="ajax-result error">
        <div class="right-container">
          <img src="@/assets/error.svg" alt="" aria-hidden="true" />
          <h2 class="mt-3 mb-3">Error!</h2>
          <p>{{ error }}</p>
        </div>
      </section>
    </div>
  </main>
  <!-- footer -->
  <footer class="footer">
    <div class="container">
      <div class="row align-items-end text-center text-xl-left">
        <div class="col-md-6 col-xl-4 mb-3 mb-md-0">
          <ul class="footer__socials">
            <li>
              <a href="https://www.linkedin.com/company/chatter-communications/" aria-label="Chatter Linked-in Page" target="_blank" rel="noopener">
                <img src="@/assets/icons/linkedin.svg" width="17" height="17" alt="LinkedIn logo" loading="lazy">
              </a>
            </li>
            <li>
              <a href="https://twitter.com/welovechatter" aria-label="Chatter Twitter Page" target="_blank" rel="noopener">
                <img src="@/assets/icons/twitter.svg" width="17" height="14" alt="LinkedIn logo" loading="lazy">
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/welovechatter" aria-label="Chatter Facebook Page" target="_blank" rel="noopener">
                <img src="@/assets/icons/facebook.svg" width="10" height="20" alt="Facebook logo" loading="lazy">
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/welovechatter/" aria-label="Chatter Instagram Page" target="_blank" rel="noopener">
                <img src="@/assets/icons/instagram.svg" width="18" height="18" alt="Instagram logo" loading="lazy">
              </a>
            </li>
            <li>
              <a href="https://vimeo.com/welovechatter" aria-label="Chatter Vimeo Page" target="_blank" rel="noopener">
                <img src="@/assets/icons/vimeo.svg" width="18" height="16" alt="Vimeo logo" loading="lazy">
              </a>
            </li>
          </ul>
          <small class="footer__copyright">Copyright &copy; {{ new Date().getFullYear() }} Chatter Communications Ltd</small>
          <nav class="footer__links d-xl-none">
            <a href="https://welovechatter.com/how-we-use-cookies" target="_blank" rel="noopener">How we use Cookies</a>
            <a href="https://welovechatter.com/the-small-print" target="_blank" rel="noopener">The Small Print</a>
          </nav>
        </div>
        <div class="col-md-6 col-xl-4 mb-3 mb-md-0 d-none d-xl-block">
          <nav class="footer__links">
            <a href="https://welovechatter.com/how-we-use-cookies" target="_blank" rel="noopener">How we use Cookies</a>
            <a href="https://welovechatter.com/the-small-print" target="_blank" rel="noopener">The Small Print</a>
          </nav>
        </div>
        <div class="col-md-6 col-xl-4 text-md-right">
          <!-- real living wage employer -->
          <a href="https://www.livingwage.org.uk/" target="_blank" rel="noopener">
            <img src="@/assets/real-living-wage-employer.svg" alt="We are a Real Living Wage employer" class="footer__certification mr-4 lazyautosizes lazyloaded" width="120" height="95" loading="lazy">
          </a>
          <!-- cyber essentials certification -->    
          <img src="@/assets/cyberessentials_certification_mark_colour.png" alt="Cyber Essentials Certification Mark" class="footer__certification lazyautosizes lazyloaded" width="80" height="96" loading="lazy">
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
// import { mapState } from 'vuex'
import Header from './components/Header.vue';
import Logo from './components/Logo.vue';
import isURL from 'validator/es/lib/isURL'

export default {
  name: 'App',
  components: {
    Header, Logo,
  },
  data() {
    return {
      finished: false,
      loading: false,
      error: null,
      inputText: '',
      emailAddress: '',
      subscribeToOffers: false,
    };
  },
  computed: {
    // ...mapState([
    //   'remaining',
    //   'user',
    // ]),
    formDisabled() {
      return this.loading; // || this.remaining === 0;
    },
    inputTextClass() {
      return {
        'sidebar__form__input--text': this.inputText.length > 0 && !isURL(this.inputText),
      };
    },
    authPage() {
      const { matched } = this.$router.currentRoute.value;
      return matched?.[0]?.path.indexOf('/auth') >= 0;
    },
  },
  watch: {
    '$route': {
      handler: function() {
        this.finished = false;
      },
    },
  },
  methods: {
    formSubmit($event) {
      $event.preventDefault();
      this.loading = true;
      this.finished = false;
      this.error = null;

      const formData = new FormData();
      formData.set('type', isURL(this.inputText.trim()) ? 'url' : 'text');
      formData.set('urlOrText', this.inputText);
      formData.set('emailAddress', this.emailAddress);
      formData.set('subscribe', this.subscribeToOffers);

      const options = {
        method: 'POST',
        headers: { 'Accept': 'application/json' },
        body: formData,
      };

      fetch('/api/enqueue', options)
        .then((res) => {
          if (!res.ok) throw new Error("Bad response!");
          return res.json();
        })
        .then((data) => {
          // any status which is not "queued" is an error!
          if (data?.status !== 'queued') {
            this.error = data.error_message;
          }
        })
        .catch(() => this.error = 'There was an error sending your request. Try again later.')
        .finally(() => {
          this.loading = false;
          this.finished = true;
        });
    },
  },
};
</script>

<style lang="scss">
@import 'reset-css';

@font-face {
  font-family: 'Nexa';
  src: url('assets/fonts/Nexa-Regular.woff2') format('woff2'),
       url('assets/fonts/Nexa-Regular.woff') format('woff'),
       url('assets/fonts/Nexa-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Nexa';
  src: url('assets/fonts/Nexa-Black.woff2') format('woff2'),
       url('assets/fonts/Nexa-Black.woff') format('woff'),
       url('assets/fonts/Nexa-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  font-family: 'Nexa', -apple-system, sans-serif;
  line-height: 1.4;
  color: $secondary-colour;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:is(h1, .h1) {
  
}

:is(h2, .h2) {
  font-size: 28px;
  font-weight: bold;
  line-height: 1.2;
  text-transform: uppercase;
}

:is(h3, .h3) {
  font-size: 24px;
  font-weight: normal;
  line-height: 1.3;
  text-transform: none;
}

p {
  &:not(:last-of-type) {
    margin-bottom: 15px;
  }
}

strong {
  font-weight: bold;
}

input, button, select, textarea {
  font-family: inherit;
  line-height: 1.3;
  transition: all 150ms linear;

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
}

a {
  color: $colour-blue;
  text-decoration: none;
  transition: all 100ms linear;

  &:hover,
  &:focus {
    color: #000;
  }
}

#app {
  display: flex;
  flex-direction: column;
  height: 100%;

  .main {
    flex: 1 0 auto;
    height: auto;

    /*
    @media print {
      margin-left: 0 !important;
    }

    @media (max-width: $break-layout-single-column) {
      display: flex;
      flex-direction: column;

      .content {
        flex: 1 0 auto;
      }
    }

    @media (min-width: $break-layout-single-column) {
      margin-left: 40%;
    }

    &--full {
      margin-left: 0;
    }
    */

    /*
    .content {
      position: relative;
      padding-left: $padding-content-h;
      padding-right: $padding-content-h;
      background-color: #fff;

      @media (min-width: $breakpoint-md) {
        padding-left: $padding-content-h-md;
        padding-right: $padding-content-h-md;
      }

      @media (min-width: $breakpoint-lg) {
        height: 100%;
        padding-left: $padding-content-h-lg;
        padding-right: $padding-content-h-lg;
      }
    }
    */
  }

  .footer {
    flex-shrink: 0;
  }
}

.main {
  display: grid;
  
  @media (min-width: 992px) {
    grid-template-columns: 0.35fr 0.75fr;
  }

  > * { min-width: 0; }
}

.section {
  padding-block: $padding-content-h-md;
}

.right-container {
  padding-inline: $padding-content-h-md;
  max-width: 800px;
}

.loading {
  .button--loading-spinner {
    span {
      opacity: 0.15;
    }

    img {
      opacity: 1;
      visibility: visible;
    }
  }
}

.button {
  position: relative;
  display: inline-block;
  min-width: 150px;
  font-size: $font-size-body-xl;
  font-weight: 900;
  color: #fff;
  text-align: center;
  padding: 8px 30px;
  background-color: $primary-colour;
  border: 3px solid $primary-colour;
  border-radius: 0;
  cursor: pointer;
  user-select: none;
  opacity: 1;

  // &:is(:hover, :focus-visible) {
  //   color: $primary-colour;
  //   background-color: transparent;
  // }

  &--loading-spinner {
    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      visibility: hidden;
      transition: opacity 150ms ease, visibility 150ms ease;
    }
  }
}

@keyframes slide-out-right {
  0% {
    opacity: 0;
    transform: translateX(-25%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slide-out-top {
  0% {
    opacity: 0;
    transform: translateY(25%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.tooltip {
  position: relative;
  cursor: help;

  &:hover,
  &:focus {
    .tooltip__popup {
      opacity: 1;
      visibility: visible;

      &--right {
        transform: translateX(0);
      }

      &--top {
        transform: translateY(0);
      }
    }
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }

  &--icon {
    position: absolute;
    top: -5px;
    right: -5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 14px;
    height: 14px;
    background-color: #000;
    border-radius: 50%;

    &::after {
      content: "i";
      font-size: 10px;
      color: #fff;
    }
  }

  &__popup {
    position: absolute;
    min-width: 200px;
    font-size: 14px;
    line-height: 1.2;
    color: #fff;
    padding: 8px 10px;
    background-color: #000;
    border-radius: 6px;
    opacity: 0;
    visibility: hidden;
    transition: all 100ms linear;
    z-index: $tooltip-z;
    user-select: none;
    pointer-events: none;

    &::after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border: solid transparent;
      border-width: $tooltip-popup-arrow-size;
      pointer-events: none;
    }

    &--top {
      bottom: calc(100% + #{$tooltip-popup-spacing});
      // left: -50%;
      transform: translateY(30%);

      &::after {
        top: 100%;
        left: 50%;
        margin-left: -$tooltip-popup-arrow-size;
        border-top-color: #000;
      }
    }

    &--right {
      top: -50%;
      left: calc(100% + #{$tooltip-popup-spacing});
      transform: translateX(-15%);

      &::after {
        top: 50%;
        right: 100%;
        margin-top: -$tooltip-popup-arrow-size;
        border-right-color: $secondary-colour-dark;
      }
    }
  }
}

.heading {
  font-weight: bold;
  line-height: 1.13;
  color: $secondary-colour-dark;
  letter-spacing: 1px;
  margin: 30px 0;
}

.sidebar {
  align-self: flex-start;
  color: #fff;
  padding: 60px;
  background-color: $secondary-colour;

  @media (min-width: 992px) {
    position: sticky;
    top: 0;
    height: 100vh;
    overflow-y: scroll;
    overscroll-behavior: contain;
  }

  h2 {
    font-weight: normal;
  }

  &__form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > div {
      width: 100%;
    }

    &__input {
      width: 100%;
      font-size: 14px;
      color: $secondary-colour;
      padding: 16px;
      background-color: #fff;
      border: none;
      resize: none;

      &::placeholder {
        color: $secondary-colour;
      }

      &--text {
        height: 250px;
      }
    }

    &__checkbox {
      display: flex;
      align-items: flex-start;
      gap: 15px;

      input[type="checkbox"] {
        vertical-align: bottom;
        margin: 0;
        padding: 10px;
        background-color: #fff;
        border: none;
        appearance: none;
        transition: none;
        cursor: pointer;

        &:checked {
          background: {
            color: #fff;
            image: url('assets/icons/checkmark.svg');
            repeat: no-repeat;
            position: center;
            size: 120%;
          };
        }
      }

      label {
        font-size: 13px;
        cursor: pointer;
      }
    }

    &__small {
      font-size: 12px;
    }

    &__usage {
      color: #fff;
      padding-bottom: 4px;
      border-bottom: 2px dotted #fff;
    }
  }
}

/*
.sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // background: linear-gradient(136deg, #4e1fe8, #ed954a 93%);
  background-color: $colour-blue-2;
  padding: 80px 20px 40px;
  color: #fff;
  z-index: $sidebar-z;

  @media print {
    display: none;
  }

  @media (min-width: $breakpoint-xs) {
    padding: 120px 60px;
  }

  @media (min-width: $break-layout-single-column) {
    position: fixed;
    top: 0;
    left: 0;
    width: 40%;
    height: 100%;
  }

  &--full {
    position: static;
    width: 100%;
    height: 100%;
    padding: 0;

    .sidebar__form__input {
      height: 300px !important;
    }
  }

  &__user-info {
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
    line-height: 1.1;
    padding: 20px;

    &__name {
      font-size: 14px;
      font-weight: 700;
    }

    &__login {
      min-width: 0;
      font-size: 11px;
      padding: 8px 20px;
    }

    &__logout {
      font-size: 11px;
      color: #fff;
      text-transform: uppercase;
      border-bottom: 2px dotted transparent;

      &:hover,
      &:focus {
        color: #fff;
        border-bottom: 2px dotted #fff;
      }
    }

    &__picture {
      width: 38px;
      height: 38px;
      background-color: #ccc;
      border-radius: 50%;

      img {
        width: inherit;
        height: inherit;
        object-fit: cover;
      }
    }
  }

  &__container {
    width: 100%;
    max-width: 600px;
  }

  &__title {
    font-size: 45px;
    font-weight: 900;
    color: #fff;
    margin-top: 0;

    @media (min-width: $break-layout-single-column + 1) and (max-width: 1290px) {
      font-size: 32px;
    }

    @media (max-width: 525px) {
      font-size: 32px;
    }

    &--small {
      display: inline-block;
      padding: 4px 6px;
      border-radius: 5px;
      font-size: 9px;
      background-color: #000;
      vertical-align: middle;
    }
  }
}
*/

.footer {
  font-size: 14px;
  font-weight: 300;
  padding-block: 30px;
  background-color: #fff;
  z-index: $footer-z;

  @media print {
    display: none;
  }

  .container {
    max-width: 1200px;
  }

  &__socials {
    display: flex;
    align-items: center;
    list-style-type: none;
    margin: 0 -5px 20px;
    padding: 0;

    @media (max-width: 1199px) {
      justify-content: center;
    }

    li {
      margin: 0 5px;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        background-color: $primary-colour;
        transition-property: background-color;

        &:is(:hover, :focus-visible) {
          background-color: $primary-colour-dark;
        }
      }
    }
  }

  &__copyright {
    font-size: inherit;
  }

  &__links {
    text-align: center;

    a {
      margin: 0 15px;

      &:hover,
      &:focus {
        color: var(--primary-highlight);
      }
    }
  }

  &__certification {
    display: inline-block;
  }
}

.ajax-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 18px;
  padding: 50px 0;

  &.error {
    h2 {
      color: $colour-red;
    }
  }

  .error__info {
    display: inline-block;
    font-size: 13px;
    color: #fff;
    padding: 4px 8px;
    background-color: #dd2c2c;
    border: 2px dashed darken(#dd2c2c, 25%);
  }
}

.awards {
  margin-top: 30px;
  background-color: rgba(0,0,0,0.2);
  padding: 16px;

  p {
    text-align: center;
    font-size: 0.9rem;
    color: white;
    line-height: 1.4;

    a {
      color: white;
      text-decoration: underline;
    }

    img {
      margin-top: 10px;
      border: 0;
      max-width: 200px;
      width: 100%;
      height: auto;
    }
  }
}
</style>
